$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header
    // *
    // *

    // make white header dark after long sroll
    if($(".js-header").length) {
        $(window).on("load scroll", function(){
            if($(window).scrollTop() >= 100) {
                $(".js-header").addClass("collapse");
            }
            if($(window).scrollTop() < 50) {
                $(".js-header").removeClass("collapse");
            };
        });
    };


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header language
    // *
    // *
    $(".js-header-language").click(function() {
        $(this).toggleClass("open");
    });

    $(".js-header-language").mouseleave(function() {
        if($(this).hasClass("open")) {
            $(".js-header-language").removeClass("open");
        }
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navSub
    // *
    // *
    $(".js-nav-item-parent > a").click(function(event){

        // prevent tab from redirecting to url unless tab is active
        if (!$(this).parent("li").hasClass("open")) {
            event.preventDefault();
        }

        $(".js-header").addClass("open");
        $(".js-header-nav > ul > li.active").addClass("hideActive");
        $(this).parent(".js-nav-item-parent").addClass("open").removeClass("hideActive").siblings().removeClass("open");
    });

    $(".js-header-nav").mouseleave(function() {
        $(".js-nav-item-parent").removeClass("open");
        $(".js-header").removeClass("open");
        $(".js-header-nav > ul > li.active").removeClass("hideActive");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMobile
    // *
    // *
    $(".js-nav-button").click(function(){
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $(".js-header").toggleClass("open");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length){
        $heroSlider.each(function(){
            $(this).slick({
                fade: true,
                dots: true,
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2800,
                speed: 1000,
                customPaging: function(slider, i) {
                    return '<button class="button heroSlider__button">' + $(slider.$slides[i]).find('[data-title]').data('title') + '</button>';
                },
                responsive: [{
                    breakpoint: 600,
                    settings: {
                        customPaging: function(slider, i) {
                            return '<div class="heroSlider__dot"></div>';
                        },
                    }
                }]
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $teaserGallery = $(".js-teaser-gallery");

    if ($teaserGallery.length){
        $teaserGallery.each(function(){
            $(this).slick({
                fade: true,
                dots: false,
                infinite: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 500,
                speed: 0
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productTeaser loop
    // *
    // * Docs: https://github.com/scottalguire/grouploop
    var $productTeaser = $(".js-product-teaser");

    if ($productTeaser.length){
        $productTeaser.grouploop({
          velocity: 0.5,
          forward: false,
          childNode: ".js-product-teaser-item",
          childWrapper: ".js-product-teaser-wrapper",
          pauseOnHover: true
      });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function(){

            var $sliderWrapper = $(this).parents(".js-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                waitForAnimate: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: $sliderWrapper.find(".js-slider-nav-prev"),
                nextArrow: $sliderWrapper.find(".js-slider-nav-next"),
                draggable: false,
                rows: 0,
            });

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length;
            $sliderWrapper.find(".js-slider-total").html(total);

            // set description
            var desc = $sliderWrapper.find(".slick-slide:eq(1)").find(".js-slider-item-desc").html();
            $sliderWrapper.find(".js-slider-desc").html(desc);

            // set fullscreen
            var url = $sliderWrapper.find(".slick-slide:eq(1)").find(".js-slider-image").data("url");
            $sliderWrapper.find(".js-slider-fullscreen").attr("href", url);

            // set current number, description and fullscreen
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var desc = $sliderWrapper.find(".slick-slide:eq(" + (nextSlide + 1) + ")").find(".js-slider-item-desc").html(),
                    url = $sliderWrapper.find(".slick-slide:eq(" + (nextSlide + 1) + ")").find(".js-slider-image").data("url");
                $sliderWrapper.find(".js-slider-current").html(nextSlide + 1);
                $sliderWrapper.find(".js-slider-desc").html(desc);
                $sliderWrapper.find(".js-slider-fullscreen").attr("href", url);
            });
        });
    }

    // // formatting numbers to n digits: 1 => 01
    // function pad(n, width, z) {
    //     z = z || '0';
    //     n = n + '';
    //     return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    // }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle button
    // *
    // *
    if ($(".js-toggle-content").length){
        $(".js-toggle-button").click(function(){
            var index = $(this).index();
            $(this).addClass("active").siblings().removeClass("active");
            $(".js-toggle-content").eq(index).show().siblings().hide();
        });

        $(".js-toggle-button").eq(0).trigger("click");
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * $videoSlider
    // *
    // *
    // *
    var $videoSlider = $(".js-video-slider");

    if ($videoSlider.length) {
        $videoSlider.each(function(){

            var $sliderWrapper = $(this).parents(".js-video-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
                waitForAnimate: false,
                prevArrow: $sliderWrapper.find(".js-video-slider-nav-prev"),
                nextArrow: $sliderWrapper.find(".js-video-slider-nav-next"),
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * $ratingCarousel
    // *
    // *
    var $ratingCarousel = $(".js-rating-carousel");

    if ($ratingCarousel.length) {
        $ratingCarousel.each(function(){


            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: false,
                waitForAnimate: false,
                variableWidth: true,
                draggable: false,
                rows: 0,
                speed: 9500,
                autoplay: true,
                autoplaySpeed:0,
                cssEase: 'linear',
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parse gallery
    // *
    // *
    if ($(".js-parse-gallery").length) {
        var galleryId = $(".js-parse-gallery").data("parse");
        $(".js-parse-gallery").find("a").attr("data-fancybox", galleryId);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * asideTeaser
    // *
    // *
    $(".js-aside-teaser-button").click(function() {
        $(this).parents(".js-aside-teaser").toggleClass("collapse");
    })

    if($(".js-aside-teaser").length) {
        if ($(window).width() < 1400) {
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll > 600) {
                    $(".js-aside-teaser").addClass("collapse");
                }

                if (scroll < 500) {
                    $(".js-aside-teaser").removeClass("collapse");
                }
            });
        }
        else {
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll > 1100) {
                    $(".js-aside-teaser").addClass("collapse");
                }

                if (scroll < 1000) {
                    $(".js-aside-teaser").removeClass("collapse");
                }
            });
        }
    }





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * product options
    // *
    // *

    $(".js-product-options").on("change", function(){
        var option = $(this).val(),
            $cover = $(".js-product-options-cover"),
            $coverLink = $(".js-product-options-coverlink"),
            $soldoutButton = $(".js-product-options-soldout"),
            $button = $(".js-cart-button"),
            image = $cover.data("cover-" + option),
            stock = $cover.data("stock-" + option),
            price = $(this).find(':selected').data("price");
            window.location.hash = option;

        // change price
        $(".js-product-price").html(price);

        // prevent sold items from add to cart
        if (stock <= 0) {
            $button.prop('disabled', true);
            $soldoutButton.fadeIn();
        }else {
            $button.prop('disabled', false);
            $soldoutButton.fadeOut();
        }

        $cover.attr("src", image);
        $coverLink.attr("href", image);
    });

    var hash = window.location.hash;

    if (hash.length && $(".js-product-options".length)) {
        var hash = hash.slice(1);
        $('.js-product-options').val(hash);
    }

    $(".js-product-options").trigger("change");




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * layout toggle
    // *
    // *
    $(".js-layout-toggle").click(function() {
        $(this).toggleClass("active").siblings(".js-layout-aside").slideToggle();
    })

    if($(window).width() < 1050) {
        if($(".js-layout-aside-shop li.active").length) {
            $(".js-layout-toggle").trigger("click");
        }
    }





});
